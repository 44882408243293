import React from "react";
import { BoarderBox } from "../../../myComponent/BoarderBox";
import { Avatar, Box, HStack } from "@chakra-ui/react";
import ColumnItem from "../../../myComponent/ColumnItem";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../../pages/User/components/NoDataFound";

const LeadListItem = ({
  item,
  onClickCheckbox,
  onClickBox,
  onClickCall,
  onClickConversations,
  onClickNotes,
  onClickMeetings,
  onStatusChange,
}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/lead/${item._id}`);
  };

  // console.log("data", item);

  // Check if item is available
  if (!item) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <NoDataFound message="No Lead In The System" name="NoUser" />
      </Box>
    );
  }

  return (
    <BoarderBox
      onClickCheckbox={onClickCheckbox}
      onClickCall={onClickCall}
      onClickConversations={onClickConversations}
      onClickNotes={onClickNotes}
      onClickMeetings={onClickMeetings}
      onStatusChange={onStatusChange}
      onClickBox={onClickBox}
      item={item}
      showCalls
      showConversation
      showNotes
      ShowMeetings
      showStatusChange
    >
      <HStack
        width={"100%"}
        alignItems={"flex-start"}
        marginStart={"15px"}
        padding={"15px 0px"}
      >
        <Avatar
          size="sm"
          name={item?.name || null}
          color={"white"}
          fontWeight={"900"}
          marginTop={"5px"}
        />
        <Box width={"100%"} marginStart={"5px"}>
          <HStack width={"100%"} alignItems={"flex-start"}>
            <ColumnItem
              title={"Lead Name :"}
              value={item?.name || "N/A"}
              width={"25%"}
              noOfLines={2}
            />
            <ColumnItem
              title={"Email Address :"}
              textTransform="lowercase"
              value={item?.email || "N/A"}
              width={"25%"}
            />
            <ColumnItem
              title={"Mobile Number :"}
              value={item?.mobile || "N/A"}
              width={"25%"}
            />
            <ColumnItem
              title={"Assign To :"}
              value={item?.assignedUser?.name || "N/A"}
              width={"25%"}
            />
          </HStack>
          <ColumnItem
            title={"Remark :"}
            marginTop={2}
            value={item?.remark?.remarkBody || "N/A"}
            width={"90%"}
          />
        </Box>
      </HStack>
    </BoarderBox>
  );
};

export default LeadListItem;
